import ArithmaticScale from "../../../../Utils/Scales/ArithmaticScale.js"
import DatagraphDataType from "../../../../Constants/DatagraphDataType.js";
import { getKpiIndicatorMenu } from "./IndicatorFlyoutMenuSaga.js";
import graphApi from "../../../../ServiceApi/Apis/GraphApi.js";
import GraphType from "GraphType";
import moment from "moment";
import periodicityHelper from "../../../../Utils/PeriodicityHelper.js";
import StringUtil from 'StringUtil';
import SymbolType from "SymbolType";
import { TabDataGraphKpiConstants } from '../../../../Constants/TabDataGraphKpiConstants';
import TimeTrackingWindow from "../../../../RayCustomControls/TimeTrackingWindow.js";
import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { getDatagraphStates, KpiModuleReducerState, priceChartReducerselect } from "../../../../Reducers/NavDataGraph/TabDataGraph/selectors.js";
// import { map } from "underscore";

const { ActionTypes } = TabDataGraphKpiConstants;

function circlePath(cx, cy, r) {
    try {
        return `M ${cx} ${cy} m -${r}, 0 a ${r},${r} 0 1,0 ${r * 2},0 a ${r},${r} 0 1,0 -${r * 2},0 `;
        ;
    } catch (error) {
        console.log(`Error occurs in KpiModule.js, circlePath ${error}`);
    }
}
function getLinePath(chartData) {
    try {
        let d = '';
        if (chartData.length > 0) {
            if (chartData.length === 1) {
                d += `M ${chartData[0].xAxis} ${(chartData[0].yPrice).toFixed(0)}`;
                d += `L ${chartData[0].xAxis} ${(chartData[0].yPrice).toFixed(0)}`;
            }
            else {
                for (let index = 0; index < chartData.length - 1; index++) {
                    if (chartData[index].yPrice && index < chartData.length) {
                        d += `M ${chartData[index].xAxis} ${(chartData[index].yPrice).toFixed(0)}`;
                        d += ` L ${chartData[index + 1].xAxis} ${(chartData[index + 1].yPrice).toFixed(0)} `;
                    }
                }
            }
        }
        return d;
    } catch (error) {
        console.log(`Error occurs in KpiModule.js, getLinePath ${error}`);
    }
}
function PrepareElipses2(chartData, majorPeriodicity) {
    try {
        let d = '';
        if ((majorPeriodicity !== GraphType.Annual && majorPeriodicity !== GraphType.Quarterly) && chartData.length > 0) {
            for (let index = 0; index <= chartData.length - 1; index++) {
                if (chartData[index].yPrice && index < chartData.length) {
                    d += circlePath(chartData[index].xAxis, chartData[index].yPrice.toFixed(0), 2.5);
                }
            }
        }
        return d;
    } catch (error) {
        console.log(`Error occurs in KpiModule.js, PrepareElipses2 ${error}`);
    }
}
function getDate(indate, majorPeriodicity) {
    try {
        let date = indate;
        let day = date.day();
        if (majorPeriodicity === GraphType.Weekly) {
            if (day !== 5) {
                day = day < 5 ? (5 - day) : (5 - day + 7);
                date = date.add(day, 'days');
            }
        }
        else if (majorPeriodicity === GraphType.Monthly) {
            date = date.endOf('month');
            day = date.day();
            if (day === 0) {
                date = date.subtract(2, "days");
            }
            else if (day === 6) {
                date = date.subtract(1, "days");
            }
        }
        else {
            if (day === 0) {
                date = date.subtract(2, 'days');
            }
            else if (day === 6) {
                date = date.subtract(1, 'days');
            }
        }
        date = moment(date, "MM-DD-YYYY 00:00:00");
        return date;
    } catch (error) {
        console.log(`Error occurs in KpiModule.js, getDate ${error}`);
    }
}
function getKpiDate(kpiDataPoints, periodicity) {
    let date = '';
    if (periodicity === GraphType.Annual) {
        date = getDate(moment(`12-31-${kpiDataPoints.fiscalYear}`, "MM-DD-YYYY 00:00:00"), periodicity);
    } else if (periodicity === GraphType.Daily) {
        date = StringUtil.getLongToMoment(kpiDataPoints.endDate);
        const day = date.day();
        if (day === 0) {
            date = date.subtract(2, 'days');
        }
        else if (day === 6) {
            date = date.subtract(1, 'days');
        }
    } else {
        date = StringUtil.getLongToMoment(kpiDataPoints.endDate);
        date = getDate(date, periodicity);
    }
    return date;
}
function getDataPoints(kpiDataPoints, scale, startDate, pricePanelData, isHistoric, nodeWidth, nodeCount, majorPeriodicity) {
    try {
        const chartData = [];
        let nodeAdjustment = 0;
        if (majorPeriodicity === GraphType.Monthly || majorPeriodicity === GraphType.Quarterly || majorPeriodicity === GraphType.Annual) {
            nodeAdjustment = 1;
        }
        let xAxis = nodeWidth * (nodeCount + nodeAdjustment);
        let hsfResults = pricePanelData ? pricePanelData.HsfData.HSFResults : '';
        let z = 0;
        const kpiStartDate = getKpiDate(kpiDataPoints[z], majorPeriodicity);
        if (moment(moment(startDate).format("MM-DD-YYYY")).isAfter(moment(kpiStartDate).format("MM-DD-YYYY")) && !isHistoric) {
            return chartData;
        }
        const hsfActCount = hsfResults.length;
        hsfResults = hsfResults.filter((obj) => moment(moment(obj.Date).format("MM-DD-YYYY")).isSameOrBefore(moment(kpiStartDate).format("MM-DD-YYYY")));
        const hsfModCount = hsfResults.length;
        const diffCount = hsfActCount - hsfModCount;
        xAxis = xAxis - diffCount * 4;

        for (let j = 0; j < hsfResults.length; j++) {
            xAxis -= 4;
            if (chartData.length > 2) {
                if (xAxis < -(chartData[0].xAxis - chartData[2].xAxis)) {
                    return chartData;
                }
            }
            if (chartData.length === 0 && Math.sign(xAxis) === -1) {
                return chartData;
            }
            if (z < kpiDataPoints.length) {
                const val = kpiDataPoints[z].reportedValue;
                let date = '';
                if (majorPeriodicity === GraphType.Annual) {
                    date = getDate(moment(`12-31-${kpiDataPoints[z].fiscalYear}`, "MM-DD-YYYY 00:00:00"), majorPeriodicity);
                } else if (majorPeriodicity === GraphType.Daily) {
                    date = StringUtil.getLongToMoment(kpiDataPoints[z].endDate);
                    const day = date.day();
                    if (day === 0) {
                        date = date.subtract(2, 'days');
                    }
                    else if (day === 6) {
                        date = date.subtract(1, 'days');
                    }
                } else {
                    date = StringUtil.getLongToMoment(kpiDataPoints[z].endDate);
                    date = getDate(date, majorPeriodicity);
                }
                if (moment(hsfResults[j].Date).format("MM-DD-YYYY") === moment(date).format("MM-DD-YYYY")) {
                    chartData.push({
                        Date: hsfResults[j].Date,
                        yPrice: scale.ComputeY(val),
                        xAxis: xAxis,
                        yValue: val
                    });
                    z++;
                }
            }
        }
        return chartData;
    } catch (error) {
        console.log(`Error occurs in KpiModule.js, getDataPoints ${error}`, error);
    }
}
function getMinMaxArray(kpiDataPoints, startDate, pricePanelData, nodeWidth, nodeCount, isHistoric, majorPeriodicity,) {
    try {
        const chartData = [];
        let xAxis = nodeWidth * (nodeCount - 1);
        const hsfResults = pricePanelData ? pricePanelData.HsfData.HSFResults : '';
        let z = 0;
        const kpiStartDate = getKpiDate(kpiDataPoints[z], majorPeriodicity);
        if (moment(moment(startDate).format("MM-DD-YYYY")).isAfter(moment(kpiStartDate).format("MM-DD-YYYY")) && !isHistoric) {
            return chartData;
        }
        for (let j = 0; j < hsfResults.length; j++) {
            xAxis -= 4;
            if (chartData.length > 2) {
                if (xAxis < -(chartData[0].xAxis - chartData[1].xAxis)) {
                    break;
                }
            }
            if (z < kpiDataPoints.length) {
                const val = kpiDataPoints[z].reportedValue;
                let date = '';
                if (majorPeriodicity === GraphType.Annual) {
                    date = getDate(moment(`12-31-${kpiDataPoints[z].fiscalYear}`, "MM-DD-YYYY 00:00:00"), majorPeriodicity);
                } else {
                    date = StringUtil.getLongToMoment(kpiDataPoints[z].endDate);
                    date = getDate(date, majorPeriodicity);
                }
                if (moment(hsfResults[j].Date).format("MM-DD-YYYY") === moment(date).format("MM-DD-YYYY")) {
                    chartData.push({
                        value: val,
                        xAxis: xAxis,
                    });
                    z++;
                }
            }
        }
        return chartData;
    } catch (error) {
        console.log(`Error occurs in KpiModule.js, getMinMaxArray ${error}`);
    }
}
function* getKpiIndicatorData({ symbol, periodicity, targetcur }) {
    try {
        TimeTrackingWindow.beginKPIPanelTimeTracker();
         const periodicityId = periodicityHelper.convertToPeriodicity(periodicity);
        const result = yield call(graphApi.GetKpiItemsBySymbol, symbol, periodicityId, '', targetcur);
        if (result && !result.responseHeader.error) {
            TimeTrackingWindow.endKPIPanelApiTimeTracker();

            const kpiDataSource = result.isKpiDataSource ? 'ES' : 'DB';
            console.log(`%c\n*** TI Kpi Data is pulled from ${kpiDataSource} ***\n`, 'color:#e60d0d;background-color:#7ac414;font-size:12px;font-family:cursive;');
            
            const KPIsDataPoints = {};
            result.kpiItemReportData.forEach((item) => {
                item.reportedValue = item.reportedValueDouble
                if (!KPIsDataPoints[item.KpiId]) {
                    KPIsDataPoints[item.KpiId] = [item];
                }
                else {
                    KPIsDataPoints[item.KpiId].push(item);
                }
            })
            const availableMenus = result.kpiAvialableData.sort((a, b) => (a.KpiName.toUpperCase() < b.KpiName.toUpperCase()) ? -1 : (a.KpiName.toUpperCase() > b.KpiName.toUpperCase()) ? 1 : 0);
            yield put({
                type: TabDataGraphKpiConstants.ActionTypes.UPDATE_ALL_KPI_DATASET,
                KPIsDataPoints,
                availableMenus
            })
            yield fork(processKPIIndicator, { isNotResize: true })
        } else {
            TimeTrackingWindow.setApiTimeTrackError(DatagraphDataType.KPIPanel);;
            TimeTrackingWindow.endKPIPanelApiTimeTracker();
        }
    }
    catch (error) {
        TimeTrackingWindow.setApiTimeTrackError(DatagraphDataType.KPIPanel);
        TimeTrackingWindow.endKPIPanelApiTimeTracker();
        console.log(`Error occurs in KPIIndicatorSaga.js, in getKpiIndicatorData, ${error}`);
    }
}
function* processKPIIndicator({ isNotResize }) {
    try {
        const { pricePanelData, isPricePanelDataReady, periodicity, majorPeriodicity, nodeWidth, nodeCount, isHistoric, isIntraday, viewsSettings } = yield select(getDatagraphStates);
        const { KPIsDataPoints, availableMenus } = yield select(KpiModuleReducerState);
        if (isPricePanelDataReady && availableMenus.length > 0 && !isIntraday) {
            isNotResize && TimeTrackingWindow.beginKPIPanelRenderTimeTracker();
            const { startDate } = yield select(priceChartReducerselect);
            if(isNotResize){
                yield fork(getKpiIndicatorMenu);
            }
            const startDateIndex = pricePanelData.IndexClosingResults.HSFResults[0].Date;
            const startDateHsf = pricePanelData.HsfData.HSFResults[0].Date;
            const processedKpiResults = {};
            const kpiSettingsDict = {};
            const Kpisettings = viewsSettings.KpiSettings;
            Object.entries(KPIsDataPoints).forEach(([KpiId, value]) => {
                const scale = new ArithmaticScale();
                const datas = periodicity === GraphType.Annual ?
                    value.filter((obj) => moment(obj.fiscalYear) < moment(startDateIndex).year()) :
                    value.filter((obj) => moment(moment(StringUtil.getDataPointsDate(obj.endDate, periodicity)).format('YYYY-MM-DD')).isSameOrBefore(moment(startDateIndex).format('YYYY-MM-DD')));

                const minMaxdataPoints = getMinMaxArray(datas, startDate, pricePanelData, nodeWidth, nodeCount, isHistoric, majorPeriodicity);
                let Lowpt = 0;
                let Hipt = 0;
                if (minMaxdataPoints.length > 0) {
                    Lowpt = minMaxdataPoints.reduce((min, p) => p.value && p.value < min ? p.value : min, minMaxdataPoints[0].value);
                    Hipt = minMaxdataPoints.reduce((max, p) => p.value && p.value > max ? p.value : max, minMaxdataPoints[0].value);
                }
                let minVal = parseFloat(Lowpt.toFixed(1));
                let maxVal = parseFloat(Hipt.toFixed(1));
                if (minVal === maxVal) {
                    maxVal = maxVal + (maxVal / 100);
                }
                if (minVal === 0.5) {
                    minVal = minVal - (minVal / 100);
                }
                scale.InitScale(parseFloat(minVal), parseFloat(maxVal), 70, '', 2, 28 * 4, SymbolType.USSTOCK, NaN, NaN, true, false, true);

                const datapnts = getDataPoints(datas, scale, startDate, pricePanelData, isHistoric, nodeWidth, nodeCount, majorPeriodicity);
                const solidPathData = getLinePath(datapnts);
                const points = PrepareElipses2(datapnts, majorPeriodicity);
                processedKpiResults[KpiId] = { KpiId, scale, solidPathData, points, startDateIndex, startDateHsf }
                kpiSettingsDict[KpiId] = Kpisettings[KpiId][majorPeriodicity];
            });
            yield put({
                type: ActionTypes.KPI_MENU_LOADED,
                processedKpiResults,
                kpiSettingsDict
            });
            isNotResize && TimeTrackingWindow.endKPIPanelLoadEndTimeTracker();
        }
    }
    catch (error) {
        isNotResize && TimeTrackingWindow.setTimeTrackRenderError(DatagraphDataType.KPIPanel);
        isNotResize && TimeTrackingWindow.endKPIPanelLoadEndTimeTracker();
        console.log(`Error occurs in KpiModule.js, getKpiMenu ${error}`);
    }
}
////////////////////////////////////////////////////
export function* watchProcessKpiIndicator() {
    yield takeEvery(ActionTypes.PROCESS_KPI_INDICATOR_DATA, processKPIIndicator);
};
export function* watchGetKpiIndicatorData() {
    yield takeEvery(ActionTypes.GET_KPI_INDICATOR_DATA, getKpiIndicatorData);
};
