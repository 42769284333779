import React from "react";
import ScrollBarStore from "../../Stores/ScrollBar/ScrollBarStore";
import { isString } from "underscore";

let scroll_ids = [];
export default class ScrollBar extends React.Component {
  constructor(props) {
    super(props);
    this.onmousemove = this.onmousemove.bind(this);
    this.onmouseup = this.onmouseup.bind(this);
    this.refresh = this.refresh.bind(this);
    this.ssb_onscroll = this.ssb_onscroll.bind(this);
    this.clear = this.clear.bind(this);
    this.ssb = {
      aConts: [],
      mouseY: 0,
      N: 0,
      asd: 0, // active scrollbar element
      sc: 0,
      sp: 0,
      to: 0,
      v: 'v',
      h: 'h'
    },
      this.cont = null;
    this.cont_id = null;
    this.vratio = 0;
    this.hratio = 0;
    this.sw = 18; // scrollbar width
    this.sh = 18; // scrollbar height
    this.isOnwheel = false;
  }

  componentWillUnmount() {
    ScrollBarStore.scroll_ids[this.cont_id] = null;

    function removeEvent(o, e, f) {
      if (window.addEventListener) { o.removeEventListener(e, f); }
      if (window.attachEvent) { o.detachEvent('on' + e, f); }
      return false;
    }
    removeEvent(document, 'mousemove',  this.onmousemove);
    removeEvent(document, 'mouseup', this.onmouseup);
    removeEvent(window, 'resize', this.refresh);
    clearTimeout(this.ssb.to);
  }

  shouldComponentUpdate(){
    return this.props.shouldUpdate;
  }

  scrollbar(cont_id, isVerticalScroll, isHorizontalScroll, scrollOnHover, mouseUp) {

    scroll_ids = ScrollBarStore.scroll_ids;
    let vScroll = isVerticalScroll || false;
    let hScroll = isHorizontalScroll || false;

    this.cont_id = cont_id;
    let cont = document.getElementById(this.cont_id);
    if (!cont) return;

    if (scroll_ids[this.cont_id] && scroll_ids[this.cont_id].node != null) {
      if (vScroll && hScroll) {
        if (scroll_ids[this.cont_id].vScroll && scroll_ids[this.cont_id].hScroll) {
          if (cont && cont.parentNode) {
            let el = cont.parentNode.getElementsByClassName('ssb_v');
            if (el[0] != undefined) el[0].parentNode.removeChild(el[0]);
            el = cont.parentNode.getElementsByClassName('ssb_h');
            if (el[0] != undefined) el[0].parentNode.removeChild(el[0]);
          }
        }
      }
      else if (vScroll && !hScroll) {
        if (scroll_ids[this.cont_id].vScroll) {
          if (cont && cont.parentNode) {
            let el = cont.parentNode.getElementsByClassName('ssb_v');
            if (el[0] != undefined) el[0].parentNode.removeChild(el[0]);
          }
        }
      }
      else if (!vScroll && hScroll) {
        if (scroll_ids[this.cont_id] && scroll_ids[this.cont_id].hScroll) {
          if (cont && cont.parentNode) {
            let el = cont.parentNode.getElementsByClassName('ssb_h');
            if (el[0] != undefined) el[0].parentNode.removeChild(el[0]);
          }
        }
      }
      else return;
    }

    // perform initialization
    if (!this.init()) return false;

    if (!scroll_ids[this.cont_id]) {
      scroll_ids[this.cont_id] = {
        vScroll: vScroll,
        hScroll: hScroll,
        node: null,
        cloneNode: null,
        activeScroll: null,
        scrollOnHover: scrollOnHover,
        scrollTop: 0
      };
    }
    if (scroll_ids[this.cont_id].node == null) {
      let cont = document.getElementById(this.cont_id);
      if (!cont) return;

      scroll_ids[this.cont_id].node = cont;
      cont.cont_id = this.cont_id;

      let cont_clone = cont.cloneNode(false);

      scroll_ids[this.cont_id].cNode = cont_clone;
      cont.parentNode.appendChild(cont_clone);
      cont_clone.parentNode.appendChild(cont);
      cont.style.position = 'absolute';
      cont.style.left = cont.style.top = '0px';
      cont.style.width = cont.style.height = '100%';

      if (scroll_ids[this.cont_id].scrollOnHover) cont.style.overflow = 'hidden';
      else if (scroll_ids[this.cont_id].vScroll && scroll_ids[this.cont_id].hScroll) cont.style.overflow = 'auto';

      if (!scroll_ids[this.cont_id].vScroll) cont.style.overflowY = 'hidden';
      if (!scroll_ids[this.cont_id].hScroll) cont.style.overflowX = 'hidden';

      scroll_ids[this.cont_id].cloneNode = true;
      let el = document.getElementById(this.cont_id);
      el.parentNode.removeChild(el);
      cont.sg = false;
    }

    if (mouseUp) scroll_ids[this.cont_id].mouseUpCallback = mouseUp;

    // adding new container into array
    if(this.ssb.aConts.includes(cont))
        this.ssb.aConts[this.ssb.aConts.indexOf(cont)] = cont;
    else
      this.ssb.aConts[this.ssb.N++] = cont;

    if (scroll_ids[this.cont_id].hScroll) scroll_ids[this.cont_id].activeScroll = this.ssb.h;
    if (scroll_ids[this.cont_id].vScroll) scroll_ids[this.cont_id].activeScroll = this.ssb.v;

    if (scroll_ids[this.cont_id].scrollOnHover) {
      cont.onmousemove = function () {
        if (scroll_ids[this.cont_id].hScroll && scroll_ids[this.cont_id].vScroll) {
          cont.style.overflow = 'auto';
        }
        if (scroll_ids[this.cont_id].hScroll && !scroll_ids[this.cont_id].vScroll) {
          cont.style.overflowX = 'auto';
        }
        if (!scroll_ids[this.cont_id].hScroll && scroll_ids[this.cont_id].vScroll) {
          cont.style.overflowY = 'auto';
        }
        this.isOnwheel = false;
      }
      cont.onmouseout = function () {
        if(this.isOnwheel){
          return false;
        }
        cont.style.overflowX = 'hidden';
        cont.style.overflowY = 'hidden';
        cont.style.overflow = 'hidden';
      }
      cont.onwheel = function () {
        this.isOnwheel = true;
      }
    }

    let self = this;
    if (scroll_ids[this.cont_id].vScroll) {
      //creating scrollbar child elements
      cont.v = this.create_div('ssb_v', cont, cont.parentNode);
      cont.vst = this.create_div('ssb_v_st', cont, cont.v);
      cont.vsb = this.create_div('ssb_v_sb', cont, cont.v);
      cont.vsu = this.create_div('ssb_v_up', cont, cont.v);
      cont.vsd = this.create_div('ssb_v_down', cont, cont.v);
      cont.vsb.onmousedown = function (e) {
        if (!cont.sg) {
          if (!e) e = window.event;

          cont.yZ = e.screenY;
          cont.xZ = e.screenX;
          cont.sZ = cont.scrollTop;
          cont.sg = true;
          self.ssb.asd = cont;
          self.className = 'ssb_v_sb ssb_v_sb_down';
          if (scroll_ids[self.cont_id]) scroll_ids[self.cont_id].activeScroll = self.ssb.v;
        }
        self.isMouseDown = true;
        return false;
      }

      // on mouse down on free track area - move our scroll element too
      // cont.vst.onmousedown = function (e) {
      //   if (!e) e = window.event;
      //   self.ssb.asd = cont;
      //   self.ssb.mouseY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
      //   for (var o = cont, y = 0; o != null; o = o.offsetParent) y += o.offsetTop;
      //   this.cont.scrollTop = (self.ssb.mouseY - y - (self.vratio * cont.offsetHeight / 2) - self.sw * 2) / self.vratio;
      //   cont.vsb.onmousedown(e);
      //   if (scroll_ids[self.cont_id]) scroll_ids[self.cont_id].activeScroll = self.ssb.v;
      // }
      cont.vst.onmousedown = function (e) {
        if (!e) e = window.event;

        const previousScrollTop = cont.scrollTop;

        self.ssb.asd = cont;
        self.ssb.mouseY = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;

        let y = 0;
        for (var o = cont; o != null; o = o.offsetParent) y += o.offsetTop;

        const newScrollTop = (self.ssb.mouseY - y - (self.vratio * cont.offsetHeight / 2) - self.sw * 2) / self.vratio;

        // Incremental scroll instead of jump
        if (newScrollTop > previousScrollTop) {
          cont.scrollTop = previousScrollTop + cont.offsetHeight;
        } else {
          cont.scrollTop = previousScrollTop - cont.offsetHeight;
        }

        cont.vsb.onmousedown(e);

        if (scroll_ids[self.cont_id]) {
          scroll_ids[self.cont_id].activeScroll = self.ssb.v;
        }
      };

      // cont.vsu.onmousedown = cont.vsu.ondblclick = function (e) { self.mousedown(e, self, -1); return false; }
      // cont.vsd.onmousedown = cont.vsd.ondblclick = function (e) { self.mousedown(e, self, 1); return false; }
      cont.vsu.onmousedown = function (e) { self.mousedown(e, self, -1); self.isMouseDown = true; return false; }
      cont.vsd.onmousedown = function (e) { self.mousedown(e, self, 1); self.isMouseDown = true; return false; }

      cont.vsu.onmouseout = cont.vsu.onmouseup = this.clear;
      cont.vsd.onmouseout = cont.vsd.onmouseup = this.clear;

      cont.vsb.onmouseover = function () { if (!cont.sg) self.className = 'ssb_v_sb ssb_v_sb_over'; return false; }
      cont.vsb.onmouseout = function () { if (!cont.sg) self.className = 'ssb_v_sb'; return false; }
    }

    if (scroll_ids[this.cont_id] && scroll_ids[this.cont_id].hScroll) {

      //creating scrollbar child elements
      cont.h = this.create_div('ssb_h', cont, cont.parentNode);
      cont.hst = this.create_div('ssb_h_st', cont, cont.h);
      cont.hsb = this.create_div('ssb_h_sb', cont, cont.h);
      cont.hsu = this.create_div('ssb_h_up', cont, cont.h);
      cont.hsd = this.create_div('ssb_h_down', cont, cont.h);

      cont.hsb.onmousedown = function (e) {
        if (!cont.sg) {
          if (!e) e = window.event;
          cont.yZ = e.screenY;
          cont.xZ = e.screenX;
          cont.sZ = cont.scrollLeft;
          cont.sg = true;
          self.ssb.asd = cont;
          self.className = 'ssb_h_sb ssb_h_sb_down';
          if (scroll_ids[self.cont_id]) scroll_ids[self.cont_id].activeScroll = self.ssb.h;
        }
        self.isMouseDown = true;
        return false;
      }

      // on mouse down on free track area - move our scroll element too
      // cont.hst.onmousedown = function (e) {
      //   if (!e) e = window.event;
      //   self.ssb.asd = cont;
      //   self.ssb.mouseX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
      //   for (var o = cont, y = 0; o != null; o = o.offsetParent) y += o.offsetLeft;
      //   cont.scrollLeft = (self.ssb.mouseX - y - (self.hratio * cont.offsetWidth / 2) - self.sh * 2) / self.hratio;
      //   cont.hsb.onmousedown(e);
      //   if (scroll_ids[self.cont_id]) scroll_ids[self.cont_id].activeScroll = self.ssb.h;
      // }
      cont.hst.onmousedown = function (e) {
        if (!e) e = window.event;

        const previousScrollLeft = cont.scrollLeft;

        self.ssb.asd = cont;
        self.ssb.mouseX = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;

        let x = 0;
        for (var o = cont; o != null; o = o.offsetParent) x += o.offsetLeft;

        const newScrollLeft = (self.ssb.mouseX - x - (self.hratio * cont.offsetWidth / 2) - self.sh * 2) / self.hratio;

        if (newScrollLeft > previousScrollLeft) {
          cont.scrollLeft = previousScrollLeft + cont.offsetWidth;
        } else {
          cont.scrollLeft = previousScrollLeft - cont.offsetWidth;
        }

        cont.hsb.onmousedown(e);

        if (scroll_ids[self.cont_id]) {
          scroll_ids[self.cont_id].activeScroll = self.ssb.h;
        }
      };
    
      // cont.hsu.onmousedown = cont.hsu.ondblclick = function (e) { self.mousedown(e, self, -1); return false; }
      // cont.hsd.onmousedown = cont.hsd.ondblclick = function (e) { self.mousedown(e, self, 1); return false; }
      cont.hsu.onmousedown = function (e) { self.mousedown(e, self, -1); self.isMouseDown = true; return false; }
      cont.hsd.onmousedown = function (e) { self.mousedown(e, self, 1); self.isMouseDown = true; return false; }

      cont.hsu.onmouseout = cont.hsu.onmouseup = this.clear;
      cont.hsd.onmouseout = cont.hsd.onmouseup = this.clear;

      cont.hsb.onmouseover = function () { if (!cont.sg) self.className = 'ssb_h_sb ssb_h_sb_over'; return false; }
      cont.hsb.onmouseout = function () { if (!cont.sg) self.className = 'ssb_h_sb'; return false; }
    }

    if (!scroll_ids[this.cont_id].vScroll && cont.h) cont.h.style.width = "100%";
    if (!scroll_ids[this.cont_id].hScroll && cont.v) cont.v.style.height = "100%";

    this.cont = cont;
    this.cont.wheelTimeout = null;
    // start scrolling
    this.ssb_onscroll();

    this.refresh();
    cont.onscroll = this.ssb_onscroll;
    ScrollBarStore.scroll_ids[this.cont_id] = scroll_ids[this.cont_id];
  }

  // initialization
  init() {
    if (window.oper || (!window.addEventListener && !window.attachEvent)) { return false; }

    // temp inner function for event registration
    let self = this;
    function addEvent(o, e, f) {
      if (window.addEventListener) { o.addEventListener(e, f, false); self.ssb.w3c = true; return true; }
      if (window.attachEvent) return o.attachEvent('on' + e, f);
      return false;
    }

    // binding events
    addEvent(document, 'mousemove', this.onmousemove);
    addEvent(document, 'mouseup', this.onmouseup);
    addEvent(window, 'resize', this.refresh);
    return true;
  }

  // onscroll - change positions of scroll element
  ssb_onscroll() {
    let scrollTop = this.cont.scrollTop;
    if (scroll_ids[this.cont_id] && scrollTop == scroll_ids[this.cont_id].scrollTop) {
      if (scroll_ids[this.cont_id]) scroll_ids[this.cont_id].activeScroll = this.ssb.h;
      let v = scroll_ids[this.cont_id].vScroll && this.cont.scrollHeight > this.cont.offsetHeight ? 3 : 2;
      this.hratio = (this.cont.offsetWidth - v * this.sh) / (this.cont.scrollWidth);
      if (scroll_ids[this.cont_id] && scroll_ids[this.cont_id].activeScroll == this.ssb.h && this.cont.hsb) {
        let left = Math.floor(this.sh + this.cont.scrollLeft * this.hratio);
        if (left + this.sw + parseInt(this.cont.hsb.style.width) >= parseInt(this.offsetWidth))
          left = parseInt(this.cont.offsetWidth) - parseInt(this.cont.hsb.style.width) - this.sw;
        this.cont.hsb.style.left = left + 'px';
      }

    }

    let v = scroll_ids[this.cont_id] && scroll_ids[this.cont_id].hScroll && this.cont.scrollWidth > this.cont.offsetWidth ? 3 : 2;
    this.vratio = (this.cont.offsetHeight - v * this.sw) / this.cont.scrollHeight;
    if (this.cont.vsb) {
      let top = Math.floor(this.sw + scrollTop * this.vratio);
      if (top + this.sh + parseInt(this.cont.vsb.style.height) >= parseInt(this.cont.offsetHeight))
        top = parseInt(this.cont.offsetHeight) - parseInt(this.cont.vsb.style.height) - this.sh;
      this.cont.vsb.style.top = top + 'px';
    }
    if(scroll_ids[this.cont_id]) {
      scroll_ids[this.cont_id].scrollTop = scrollTop;
    }
  }

  // create and append div finc
  create_div(c, cont, cont_clone) {
    let o = document.createElement('div');
    o.cont = cont;
    o.className = c;
    cont_clone.appendChild(o);
    return o;
  }

  // do clear of controls
  clear() {
    let self = this;
    clearTimeout(self.ssb.to);
    this.ssb.sc = 0;
    return false;
  }

  // refresh scrollbar
  refresh() {
    let self = this;
    for (let i = 0, N = this.ssb.N; i < N; i++) {
      let o = this.ssb.aConts[i];
      self.ssb_onscroll();
      if (o.vsb) {
        o.vsb.style.width = o.vst.style.width = o.vsu.style.width = o.vsu.style.height = o.vsd.style.width = o.vsd.style.height = this.sw + 'px';
        o.vsb.style.height = Math.ceil(Math.max(this.sw * .5, this.vratio * o.offsetHeight) + 1) + 'px';

        // hide scrollbar if it's not applicable
        if (o.scrollHeight > o.clientHeight){
          o.v.style.display = '';
          if (o.h) o.h.style.width = 'calc(100% - 18px)';
        }
        else {
          o.v.style.display = 'none';
          if (o.h) o.h.style.width = '100%';
        }
      }

      if (o.hsb) {
        o.hsb.style.height = o.hst.style.height = o.hsu.style.height = o.hsu.style.width = o.hsd.style.height = o.hsd.style.width = this.sh + 'px';
        o.hsb.style.width = Math.ceil(Math.max(this.sh * .5, this.hratio * o.offsetWidth) + 1) + 'px';

        const hScrollAfter = o.parentNode.getElementsByClassName('cornor-firefox');
        const hScrollAfterEdge = o.parentNode.getElementsByClassName('cornor-ms-edge');
        // hide scrollbar if it's not applicable
        if (o.scrollWidth > o.clientWidth){
          o.h.style.display = '';
          if (o.v) o.v.style.height = 'calc(100% - 18px)';
          if (scroll_ids[o.cont_id].vScroll && o.v.style.display == '') {
            if (hScrollAfter[0] !== undefined) hScrollAfter[0].parentNode.removeChild(hScrollAfter[0]);
            if (hScrollAfterEdge[0] !== undefined) hScrollAfterEdge[0].parentNode.removeChild(hScrollAfterEdge[0]);
            this.create_div('cornor-firefox', o.h, o.parentNode);
            this.create_div('cornor-ms-edge', o.h, o.parentNode);
          }
          else{
            if (hScrollAfter[0] !== undefined) hScrollAfter[0].parentNode.removeChild(hScrollAfter[0]);
            if (hScrollAfterEdge[0] !== undefined) hScrollAfterEdge[0].parentNode.removeChild(hScrollAfterEdge[0]);
          }
        }
        else {
          o.h.style.display = 'none';
          if (o.v) o.v.style.height = '100%';
          if (hScrollAfter[0] !== undefined) hScrollAfter[0].parentNode.removeChild(hScrollAfter[0]);
          if (hScrollAfterEdge[0] !== undefined) hScrollAfterEdge[0].parentNode.removeChild(hScrollAfterEdge[0]);

        }
      }
    }
  }

  // arrow scrolling
  arrow_scroll(activeScrollPos) {
    let activeScroll = activeScrollPos;
    let self = this;
    if (this.ssb.sc != 0) {
      /* fixes PANWEB-1143 */
      if (activeScroll == this.ssb.v && this.ssb.asd) {
        let calculateMoveValue = self.props.itemHeight ? self.props.itemHeight * this.ssb.sc : 6 * this.ssb.sc / this.vratio;
        if (this.props.itemHeight) {
          let prevScrollTop = Math.ceil(this.ssb.asd.scrollTop);
          calculateMoveValue += prevScrollTop;
          if (calculateMoveValue < (this.ssb.asd.scrollHeight - this.ssb.asd.offsetHeight) && this.ssb.asd.cont_id !== "symbol-NupViewScrollbar-VirtualScrolling") {
            calculateMoveValue -= ((prevScrollTop % self.props.itemHeight));
          }
          this.ssb.asd.scrollTop = calculateMoveValue;
        }
        else {
          this.ssb.asd.scrollTop += calculateMoveValue;
        }
      }
      else if (activeScroll == this.ssb.h) this.ssb.asd.scrollLeft += 6 * this.ssb.sc / this.hratio;
      this.ssb.to = setTimeout(()  => self.arrow_scroll(activeScroll), this.ssb.sp);
      this.ssb.sp = 32;
    }
  }

  /* event binded functions : */
  // scroll on mouse down
  mousedown(e, o, s) {
    if (this.ssb.sc == 0) {
      if (e) {
        let activeScroll = this.ssb.v;
        if (isString(e.target.className))
          if (e.target.className.indexOf('_h') != -1) activeScroll = this.ssb.h;

        if (activeScroll == this.ssb.v) o.cont.vsb.className = 'ssb_v_sb ssb_v_sb_down';
        else if (activeScroll == this.ssb.h) o.cont.hsb.className = 'ssb_h_sb ssb_h_sb_down';
        if (scroll_ids[o.cont.cont_id]) scroll_ids[o.cont.cont_id].activeScroll = activeScroll;

        this.ssb.asd = o.cont;
        this.ssb.sc = s;
        this.ssb.sp = 400;
        this.arrow_scroll(activeScroll);
      }
      ScrollBarStore.scroll_ids[o.cont.cont_id] = scroll_ids[o.cont.cont_id];
    }
  }

  // on mouseMove binded event
  onmousemove(e) {
    if (!e) e = window.event;
    if (this.ssb.asd) {
      if (scroll_ids[this.ssb.asd.id] && scroll_ids[this.ssb.asd.id].activeScroll == this.ssb.v) {
        this.ssb.mouseY = e.screenY;
        if (this.ssb.asd.sg) this.ssb.asd.scrollTop = this.ssb.asd.sZ + (this.ssb.mouseY - this.ssb.asd.yZ) / this.vratio;
      }
      else if (scroll_ids[this.ssb.asd.id] && scroll_ids[this.ssb.asd.id].activeScroll == this.ssb.h) {
        this.ssb.mouseX = e.screenX;
        if (this.ssb.asd.sg) this.ssb.asd.scrollLeft = this.ssb.asd.sZ + (this.ssb.mouseX - this.ssb.asd.xZ) / this.hratio;
      }
    }
  }

  // on mouseUp binded event
  onmouseup(e) {
    if (!e) e = window.event;
    let tg = (e.target) ? e.target : e.srcElement;

    if (this.ssb.asd && document.releaseCapture) this.ssb.asd.releaseCapture();

    // new class name
    if (this.ssb.asd) {
      if (scroll_ids[this.ssb.asd.id] && scroll_ids[this.ssb.asd.id].activeScroll == this.ssb.v) if (this.ssb.asd.vsb) this.ssb.asd.vsb.className = (isString(tg.className) && tg.className.indexOf('scrollbar') > 0) ? 'ssb_v_sb ssb_v_sb_over' : 'ssb_v_sb';
      else if (scroll_ids[this.ssb.asd.id] && scroll_ids[this.ssb.asd.id].activeScroll == this.ssb.h) if (this.ssb.asd.hsb) if (isString(tg.className)) this.ssb.asd.hsb.className = (isString(tg.className) && tg.className.indexOf('scrollbar') > 0) ? 'ssb_h_sb ssb_h_sb_over' : 'ssb_h_sb';

      if (scroll_ids[this.ssb.asd.id]) scroll_ids[this.ssb.asd.id].activeScroll = this.ssb.v;
    }
    document.onselectstart = '';
    this.clear();
    if (this.ssb.asd) this.ssb.asd.sg = false;

    ScrollBarStore.scroll_ids[this.ssb.asd.id] = scroll_ids[this.ssb.asd.id];

    if (scroll_ids[this.ssb.asd.id] && scroll_ids[this.ssb.asd.id].mouseUpCallback && this.isMouseDown) { scroll_ids[this.ssb.asd.id].mouseUpCallback(e); }
    this.isMouseDown = false;
  }


  render() {
    let self = this;
    setTimeout(() => {
      let scrollId = self.props.scrollId;
      let vScroll = self.props.vScroll;
      let hScroll = self.props.hScroll;
      let mouseUp = self.props.mouseUp;
      let scrollOnHover = self.props.scrollOnHover;
      if (scrollId != null) self.scrollbar(scrollId, vScroll, hScroll, scrollOnHover, mouseUp);
    }, 0);
    return null;
  }
}

ScrollBar.defaultProps = {
  shouldUpdate: true
}
